<template>
  <div>
    <o-table
      ref="activityTable"
      backend-sorting
      class="table__overflow-auto activity-table fs-12"
      :class="{ 'o-table-has-pagination': activityData.length && totalPages > 1 }"
      :data="activityData"
      :default-sort="[sortField, sortOrder]"
      :default-sort-direction="defaultSortOrder"
      detail-transition="fade"
      detailed
      detailed-class="eth-tx-actions-detailed"
      hoverable
      :loading="loading"
      :mobile-cards="false"
      sort-icon="chevron-up"
      striped
      @sort="onSort"
    >
      <template
        slot="empty"
        slot-scope="props"
      >
        <div
          v-if="!loading"
          class="flex justify-center full grey-text mt-3 mb-4"
        >
          Data is empty
        </div>
      </template>
      <template
        slot="detail"
        slot-scope="props"
      >
        <div class="actions-detailed flex warning-text">
          {{ props.row.error.message }}
        </div>
      </template>
      <o-table-column
        v-slot="props"
        field="timestamp"
        label="Action Time"
        sortable
      >
        {{ props.row.timestamp ? formatDate(props.row.timestamp, 'dd.MM.yyyy HH:mm') : '-' }}
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="tag"
        label="Request"
      >
        <span
          v-for="(tag, index) in props.row.tags"
          :key="index"
        >
          {{ getLabelRequest(tag) }}
        </span>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="search"
        label="Address / Transaction"
      >
        <div
          :class="[{'link-text': props.row.meta}]"
          @click="routingGo(props.row.meta, props.row.coin)"
        >
          {{ props.row.meta ? trancateString(props.row.meta.address, 8) || trancateString(props.row.meta.txHash, 8) : '-' }}
        </div>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="coin"
        label="asset"
        sortable
      >
        <span class="uppercase">{{ props.row.coin || '-' }}</span>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="log"
        label="Outputs"
      >
        <span
          v-if="props.row.error"
          class="warning-text"
        >
          {{ props.row.error.code || trancateString(props.row.error.message, 10) }}
        </span>
        <span
          v-else
          class="agree-text"
        >
          success
        </span>
      </o-table-column>
      <o-table-column
        v-slot="props"
        field="log"
        label=""
        position="right"
        width="50"
      >
        <gl-icon
          v-if="props.row.error"
          class="pointer"
          :disabled="!props.row.error"
          height="20"
          :name="!props.row.expand ? 'close-details' : 'open-details'"
          width="20"
          @click="toggleDetails(props)"
        />
      </o-table-column>
    </o-table>
    <o-pagination
      v-if="activityData.length && totalPages > 1"
      class="users-pagination m-mb-3"
      :current.sync="currentPage"
      order="centered"
      :per-page="perPage"
      :total="total"
      @change="pageChange"
    >
      <o-pagination-button
        slot="previous"
        slot-scope="props"
        :page="props.page"
      >
        <gl-menu-item
          class="change-page mr-2"
          :disabled="props.page.disabled"
          icon="left"
          :icon-height="24"
          :icon-width="24"
          label=""
          not-outline
        />
      </o-pagination-button>

      <o-pagination-button
        slot="next"
        slot-scope="props"
        :page="props.page"
      >
        <gl-menu-item
          class="change-page"
          :disabled="props.page.disabled"
          icon="right"
          :icon-height="24"
          :icon-width="24"
          label=""
          not-outline
        />
      </o-pagination-button>
    </o-pagination>
  </div>
</template>
<script>
// Utils
import { formatDate } from '@/utils/format-date'
import { trancateString } from '@/utils/text-formatter'
import { getLabelRequest } from '@/utils/report-data-formatter'
// Vuex
import { mapActions, mapState } from 'vuex'
// Components
import GlMenuItem from '@/components/gl-menu-item.vue'
import GlIcon from '@/components/gl-icon.vue'

export default {
  components: { GlIcon, GlMenuItem },
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activityData: [],
      loading: false,
      defaultSortOrder: 'desc',
      sortField: 'timestamp',
      sortOrder: 'desc',
      pagesOptions: [5, 10,20, 50, 100],
      currentPage: 1,
      perPage: 15,
      totalPages: 1,
      total: 1,
      activityListInterval: null
    }
  },
  computed: {
    ...mapState('analytics', ['coinData'])
  },
  mounted() {
    this.loadData(true)
  },
  beforeDestroy() {
    clearTimeout(this.activityListInterval);
  },
  methods: {
    trancateString,
    ...mapActions('users', [
      'getUserActivityList',
      'getUser'
    ]),
    formatDate,
    getLabelRequest,
    routingGo(meta, type) {
      const { address, txHash } = meta;

      const buildHref = (paramName, paramValue) => {
        return this.$router.resolve({
          name: 'report',
          query: {
            [paramName]: paramValue,
            type,
          }
        }).href;
      };

      if (address) {
        window.open(buildHref('address', address), '_blank');
      }

      if (txHash) {
        window.open(buildHref('tx', txHash), '_blank');
      }
    },
    toggleDetails(props) {
      if (!props.row.error) return
      this.$refs.activityTable.toggleDetails(props.row)
      this.activityData[props.index].expand = Boolean(!this.activityData[props.index].expand)
    },
    async loadData(withLoading = false) {
      const id = this.userId || this.$route.params.id;

      if (withLoading) this.loading = true;

      try {
        const response = await this.getUserActivityList({
          id,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          count: this.perPage,
          skip: (this.currentPage - 1) * this.perPage,
        });

        const { items, totalItems } = response.data;

        this.activityData = items || [];
        this.total = totalItems;
        this.totalPages = Math.ceil(totalItems / this.perPage);

        clearTimeout(this.activityListInterval);

        this.activityListInterval = setTimeout(this.loadData, 15000);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },
    onSort(field, order) {
      this.sortOrder = order
      this.sortField = field

      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  }
}

</script>

<style>
.activity-table .o-table__td-chevron {
  display: none;
}

.activity-table .o-table__td {
  position: relative;
  border: none;
}

.activity-table .o-table__th--detailed {
  display: none;
}
</style>
