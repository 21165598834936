<template>
  <div>
    <GlAxisChart
      :loading="loading"
      :usage-statistics="statData"
      @change-range="changeDateRange"
    />
  </div>
</template>
<script>
//Vuex
import { mapActions } from 'vuex'
// Components
import GlAxisChart from '@/components/charts/gl-axis-chart.vue'

export default {
  components: {
    GlAxisChart
  },
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      statData: [],
      loading: false,
      currentRange: null,
      oneDay: 24 * 3600 * 1000,
    }
  },
  mounted() {
    const now = +new Date();
    const start = this.currentRange ? now - this.currentRange * this.oneDay : 0;

    this.loadData(start)
  },
  methods: {
    ...mapActions('users', ['getUsersStatistics']),
    loadData(startAt) {
      this.loading = true
      this.getUsersStatistics({ id: this.userId, startAt }).then(({ data: { usageStatistics } }) => {
        this.statData = usageStatistics
      }).finally(() => {
        this.loading = false
      })
    },
    changeDateRange({ startAt }) {
      this.loadData(startAt)
    },
  }
}
</script>
